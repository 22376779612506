import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.hero-button {
		min-width: 210px;
		max-width: 300px;
		margin: auto ${theme.spacing(2)} auto auto;
		z-index: 12;
	}

	.hero-container {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50vh;
		min-height: 700px;
		background-color: #2962ff;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	  	overflow: hidden;

		.hero-text-wrapper {
			text-align: left;
		}

		.hero-phone {
			max-width: 600px;
			width: auto;
			height: auto;
		}

		.text-align {
			// text-shadow: 2px 2px 5px #333;
			z-index: 10;
		}
	}

	// Backgorund image below

	// .hero-container::after {
	// 	content: '';
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	// background: rgba(0, 0, 0, 0.5);
	// 	background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
	// }

	.alpha-container {
		margin: auto;
		padding-top: ${theme.spacing(4)};
		flex-wrap: nowrap;
	}

	.alpha-container-item {
		max-width: 800px;
		width: 100%;
		padding-right: ${theme.spacing(6)};
		flex-wrap: nowrap;
	}

	.alpha-heading {
		margin-bottom: ${theme.spacing(2)};
		font-weight: 600;
	}

	.alpha-body {
		font-size: 0.9rem;
		margin-top: ${theme.spacing(1)};
	}

	.alpha-image-container {
		width: 70%;
		height: auto;
	}

	.alpha-image {
		width: 100%; 
		margin: auto; 
		margin-top: ${theme.spacing(0)};
	}

	.alpha-btn {
		max-width: 200px;
		width: 100%;
		margin-top: ${theme.spacing(3)};
	}

	${theme.breakpoints.down('md')} {
		.hero-container {
			.hero-card {
				top: 50px;
				left: 50%;
				transform: translateX(-50%);
				height: 150px;
			}

			.hero-button {
				width: 100%;
			}
		}

		.alpha-image {
			margin-top: ${theme.spacing(3)};
			max-width: 400px;
			width: 100%;
		}

		.alpha-btn {
			width: 100%;
			margin: auto;
		}
	}

	${theme.breakpoints.up(1200)} {
		.hero-container {

			display: flex;
			flex-direction: column;

			.hero-phone {
				display: block;
			}

			.hero-text-wrapper {
				text-align: left;
				max-width: 500px;
			}
		}

		.button-container {
			flex-direction: row;
		}
	}

	${theme.breakpoints.down(1200)} {
		.hero-container {
			padding: ${theme.spacing(0, 4)};

			.hero-card {
				top: 100px;
				left: 50%;
				transform: translateX(-50%);
			}

			.hero-phone {
				display: none;
			}

			.hero-text-wrapper {
				text-align: center;
				max-width: 500px;
				width: 100%;
				padding-top: ${theme.spacing(2)};
			}
		}

		.button-container {
			align-items: center;
		}

		.hero-buttons {
			margin: auto;
		}

		.hero-button {
			margin: 0;
			margin-top: ${theme.spacing(1)};
		}

		.alpha-container,
		.alpha-container-item,
		.alpha-image-container,
		.alpha-image {
			width: 100%;
			padding: 0;
		}

		.alpha-container {
			justify-content: center;
			flex-direction: row;
			flex-wrap: wrap;
		}

		.alpha-container-item {
			padding-top: ${theme.spacing(4)};
			text-align: center;
		}

		.alpha-heading {
			width: 100%;
		}

		.alpha-image {
			max-width: 600px;
		}

		.alpha-btn {
			margin-top: ${theme.spacing(4)};
		}
	}
`;
