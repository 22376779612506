import React, { useRef } from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import LogoFull from '@assets/logo/logo-full-black.inline.svg';
import LogoBlack from '@assets/card-logo-symbol-1c-black.svg';
import LogoSilver from '@assets/card-logo-symbol-2g-silver.svg';
import { Box, Fade, Grow, Typography, useMediaQuery } from '@mui/material';
import { CheckoutCard } from '@models/checkout';
import { Card } from '@models/card';
import { useRect } from '@helpers/hooks/useRect';
import useScreenSize from '@helpers/hooks/useScreenSize';

const $cardRef = React.createRef<HTMLDivElement>();

const Preview: React.FC<{
	lineOne: string;
	lineTwo?: string;
	colour: string;
}> = ({ colour, lineOne, lineTwo }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.up('lg'));
	const cardRect = useRect($cardRef);
	const { winWidth } = useScreenSize();

	return (
		<div css={styles(theme)} className={mobile ? undefined : 'preview-mobile'}>
			<Box
				ref={$cardRef}
				className={`card ${
					colour === 'black'
						? 'card-black'
						: colour === 'silver'
						? 'card-silver'
						: colour === 'gold'
						? 'card-gold'
						: ''
				}`}
			>
				<div className="card-logo">
					<img src={colour === 'black' ? LogoSilver : LogoBlack} alt="logo" />
				</div>

				<div className="card-lines">
					<div className={`card-line ${!lineTwo ? 'card-line-single' : ''}`}>
						<Fade unmountOnExit in={!lineOne}>
							<div className="card-first-line" />
						</Fade>

						<Fade unmountOnExit in={!!lineOne}>
							<div className="card-first-line card-first-line-filled">
								<Typography noWrap>{lineOne}</Typography>
							</div>
						</Fade>
					</div>

					<Grow in={!!lineTwo}>
						<div className="card-line">
							<Fade unmountOnExit in={!lineTwo}>
								<div className="card-second-line" />
							</Fade>

							<Box mt={1} />

							<Fade unmountOnExit in={!!lineTwo} timeout={{ enter: 100, exit: 500 }}>
								<div className="card-second-line card-second-line-filled">
									<Typography noWrap>{lineTwo}</Typography>
								</div>
							</Fade>
						</div>
					</Grow>
				</div>
			</Box>
		</div>
	);
};

export default Preview;
