import { css } from '@emotion/react';
import { Theme } from '@mui/material/styles';

export default (theme: Theme) => css`
	background: #161c24;
	max-height: 100%;
	padding: ${theme.spacing(6, 4)};

	.dashboard-preview-wrapper {
		padding: ${theme.spacing(10, 0)};

		.dashboard-preview-image-container {
			max-width: 80%;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
		.dashboard-preview-content {
			max-width: 700px;
			margin: auto;
			color: ${theme.palette.common.white};
			.dashboard-preview-caption {
				color: ${theme.palette.primary.main};
			}

			${theme.breakpoints.down('md')} {
				text-align: center;
			}
		}
	}
`;
