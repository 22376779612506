import React from 'react';
import { Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import styles from './styles';
import { useTheme } from '@mui/material';
import Dashboard from '@assets/dashboard-preview.png';
const DashboardPreview: React.FC<{ caption?: string; title?: string; description?: string }> = ({
	caption,
	title,
	description,
}) => {
	const theme = useTheme();
	const laptop = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div css={styles(theme)}>
			<Box className="dashboard-preview-wrapper">
				<Container maxWidth="md">
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						spacing={6}
						direction={laptop ? 'column-reverse' : 'row'}
					>
						<Grid
							item
							lg={6}
							md={6}
							sm={12}
							sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
						>
							<img src={Dashboard} className="dashboard-preview-image-container" />
						</Grid>
						<Grid item lg={6} md={6} sm={12}>
							<Stack
								direction="column"
								spacing={2}
								className="dashboard-preview-content"
							>
								<Typography
									variant="caption"
									display="block"
									gutterBottom
									className="dashboard-preview-caption"
								>
									{caption}
								</Typography>
								<Typography variant="h2" display="block">
									{title}
								</Typography>
								<Typography variant="body1" display="block">
									{description}
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	);
};

export default DashboardPreview;
