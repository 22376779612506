import React from 'react';
import { Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import styles from './styles';
import { useTheme } from '@mui/material';
import CardMulti from '@assets/card-collection.svg';
const MultiCardHero: React.FC<{ caption?: string; title?: string; description?: string }> = ({
	caption,
	title,
	description,
}) => {
	const theme = useTheme();

	return (
		<Box py={10} css={styles(theme)}>
			<Container maxWidth="md">
				<Grid container justifyContent="center" alignItems="center" spacing={4}>
					<Grid item lg={6} md={6} sm={12}>
						<Stack className="multi-card-text-wrapper" direction="column" spacing={2}>
							<Typography
								variant="caption"
								display="block"
								gutterBottom
								sx={{ color: '#2962FF' }}
							>
								{caption}
							</Typography>
							<Typography variant="h2" display="block">
								{title}
							</Typography>
							<Typography variant="body1" display="block">
								{description}
							</Typography>
						</Stack>
					</Grid>
					<Grid
						item
						lg={6}
						md={6}
						sm={12}
						sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<img src={CardMulti} className="multi-card-image" />
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default MultiCardHero;
