import { css } from '@emotion/react';
import { Theme } from '@mui/material/styles';

export default (theme: Theme) => css`
	.card-demo-caption {
		color: #2962ff;
	}

	${theme.breakpoints.between('sm', 'md')} {
		max-width: 700px;
		margin: auto;
	}

	${theme.breakpoints.down('sm')} {
		.card-preview {
			.card {
				min-width: 100%;

				.card-logo {
					width: 85%;
				}

				.card-lines {
					.card-line {
						width: 200px;
						height: 10px;
					}
				}
			}
		}
	}
`;
