import React, { useEffect, useState } from 'react';
import styles from './styles';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';

import { useTheme } from '@mui/material';
import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';

import { makeGetRequest, makePostRequest, makePutRequest } from '@helpers/requests';
import { CHECKOUT, CHECKOUTS } from '@helpers/api';
import { useSnackbar } from '@components/common/Snackbar';
import { navigate, Link } from 'gatsby';
import queryString from 'query-string';
import PaletteCard from '@components/Landing/common/PaletteCard';
import Preview from '@components/CardPreview';
import { useLocation } from '@reach/router';
import { Checkout } from '@models/checkout';
import Loader from '@components/common/Loader';
import useDidUpdateEffect from '@helpers/hooks/useDidUpdateEffect';

const CardDemo: React.FC<{ caption?: string; title?: string; description?: string }> = ({
	caption,
	title,
}) => {
	const theme = useTheme();
	const [openSnackbar] = useSnackbar();
	const location = useLocation();
	const params = queryString.parse(location.search);
	const checkoutId: any = params.checkoutId;
	const [checkout, setCheckout] = useState<Checkout | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	const phone = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		(async () => {
			try {
				if (!checkoutId) {
					setLoading(false);
					return;
				}

				const { data: checkoutData } = await makeGetRequest(CHECKOUT(checkoutId));
				setCheckout(checkoutData);
				setLoading(false);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ?? 'An error occurred attempting to get your checkout.',
						'error'
					);
					setCheckout(null);
					setLoading(false);
				}
			}
		})();
	}, []);

	const handleSubmit = async () => {
		if (!checkout) return;

		navigate(
			`/checkout/?${queryString.stringify({
				...params,
				checkoutId: checkout?._id,
			})}`
		);
	};

	const initialValues: {
		lineOne: string;
		lineTwo?: string;
		colour: 'black' | 'silver' | 'gold' | string;
	} = {
		lineOne: checkout?.card?.lineOne ?? '',
		lineTwo: checkout?.card?.lineTwo ?? '',
		colour: checkout?.card?.colour ?? 'black',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required').label('Name'),
		company: Yup.string().required('Company is required').label('Company'),
		colour: Yup.string().required('Colour is required').label('Colour'),
	});

	return (
		<div css={styles(theme)}>
			<Container maxWidth="md">
				{loading ? (
					<Loader />
				) : (
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting, setFieldValue, values }) => {
							const [saving, setSaving] = useState(false);

							useDidUpdateEffect(() => {
								(async () => {
									try {
										setSaving(true);
										const { data: checkoutData } = !!checkout
											? await makePutRequest(CHECKOUT(checkout?._id), {
													lineOne: values?.lineOne || undefined,
													lineTwo: values?.lineTwo || undefined,
													colour: values?.colour || undefined,
											  })
											: await makePostRequest(CHECKOUTS, {
													lineOne: values?.lineOne || undefined,
													lineTwo: values?.lineTwo || undefined,
													colour: values?.colour || undefined,
											  });

										if (!checkout) {
											// navigate(
											// 	`?${queryString.stringify({
											// 		...params,
											// 		checkoutId: checkoutData._id,
											// 	})}`,
											// 	{ replace: true }
											// );
										}

										setCheckout(checkoutData);
										setSaving(false);
									} catch (error) {
										if (error !== 'cancelled') {
											openSnackbar(
												error?.errorMessage ??
													`Unable to ${
														!!checkout ? 'update' : 'create'
													} checkout.`,
												'error'
											);
											setSaving(false);
										}
									}
								})();
							}, [values]);

							return (
								<Grid container spacing={6} alignItems="center">
									<Grid item xs={12} md={6} alignItems="center">
										<div style={{ maxWidth: '800px' }}>
											<Stack
												direction="column"
												alignItems={phone && 'center'}
												spacing={2}
											>
												<Typography
													variant="caption"
													display="block"
													sx={{ mb: 1 }}
													className="card-demo-caption"
												>
													{caption}
												</Typography>
												<Typography variant="h2" display="block">
													{title}
												</Typography>

												<Form className="form">
													<Field
														component={TextField}
														name="lineOne"
														type="text"
														label="Line One (Name)*"
														fullWidth
														margin="normal"
														onChange={(e) =>
															setFieldValue('lineOne', e.target.value)
														}
													/>
													<Field
														component={TextField}
														name="lineTwo"
														type="text"
														label="Line Two (Company name)"
														fullWidth
														margin="normal"
														onChange={(e) =>
															setFieldValue('lineTwo', e.target.value)
														}
													/>
													<Grid
														container
														spacing={mobile ? 1 : 2}
														sx={{ mt: 1 }}
													>
														<Grid item xs={4} sm={4} md={4} lg={4}>
															<div className="palette-card">
																<PaletteCard
																	onSelected={() =>
																		setFieldValue(
																			'colour',
																			'black'
																		)
																	}
																	colour={'black'}
																	selected={values?.colour}
																/>
															</div>
														</Grid>
														<Grid item xs={4} sm={4} md={4} lg={4}>
															<PaletteCard
																onSelected={() =>
																	setFieldValue(
																		'colour',
																		'silver'
																	)
																}
																colour={'silver'}
																selected={values?.colour}
															/>
														</Grid>
														<Grid item xs={4} sm={4} md={4} lg={4}>
															<PaletteCard
																onSelected={() =>
																	setFieldValue('colour', 'gold')
																}
																colour={'gold'}
																selected={values?.colour}
															/>
														</Grid>
													</Grid>
													{phone ? (
														<Grid
															item
															xs={12}
															md={6}
															alignSelf="flex-end"
														>
															<Box className="card-preview">
																<Preview
																	lineOne={values.lineOne ?? ''}
																	lineTwo={values.lineTwo ?? ''}
																	colour={values.colour ?? ''}
																/>
															</Box>
														</Grid>
													) : (
														''
													)}
													<Button
														type="submit"
														className="submit"
														fullWidth
														variant="contained"
														color="primary"
														component={Link}
														to="/checkout"
														disabled={isSubmitting || saving}
														sx={{ mt: 3 }}
														startIcon={
															isSubmitting ? (
																<CircularProgress
																	color="inherit"
																	size={20}
																/>
															) : undefined
														}
													>
														Buy now
													</Button>
												</Form>
											</Stack>
										</div>
									</Grid>
									{!phone ? (
										<Grid item xs={12} md={6} alignSelf="flex-end">
											<Box className="card-preview">
												<Preview
													lineOne={values.lineOne ?? ''}
													lineTwo={values.lineTwo ?? ''}
													colour={values.colour ?? ''}
												/>
											</Box>
										</Grid>
									) : (
										''
									)}
								</Grid>
							);
						}}
					</Formik>
				)}
			</Container>
		</div>
	);
};

export default CardDemo;
