import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import Spray from '@assets/spray.inline.svg';
import Stars from '@assets/stars.inline.svg';
import Footsteps from '@assets/footsteps.inline.svg';
import Feature from '@views/Home/Features/Feature';

const Features: React.FC<{ caption?: string; title?: string }> = ({ caption, title }) => {
	const theme = useTheme();
	const typography = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div css={styles(theme)}>
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Grid item lg={12}>
					<Stack direction="column" spacing={2} alignItems="center">
						<Typography
							variant="caption"
							display="block"
							gutterBottom
							sx={{ color: '#2962FF' }}
						>
							{caption}
						</Typography>
						<Typography
							variant="h2"
							display="block"
							align={typography ? 'center' : undefined}
						>
							{title}
						</Typography>
					</Stack>
				</Grid>
				<Grid item sx={{ mt: 10 }}>
					<Grid container spacing={4} justifyContent="center" alignItems="center">
						<Grid item sm={8} md={4}>
							<Feature
								title="Customise"
								description="Set up your profile to reflect your personality. Whether its a personal account or unity across a large business, your digital card can reflect"
								svg={<Spray />}
							/>
						</Grid>
						<Grid item sm={8} md={4}>
							<Feature
								title="General Leads"
								description="Your are in control of following up your leads. Say goodbye to the discarded paper business card"
								svg={<Stars />}
							/>
						</Grid>
						<Grid item sm={8} md={4}>
							<Feature
								title="Track"
								description="Track statistics for your Wave Card. See which salesman is performing best or edit individual cards on an overview basis"
								svg={<Footsteps />}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Features;
