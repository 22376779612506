import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	background: linear-gradient(rgba(223, 227, 232, 0) 0%, ${theme.palette.mode === 'light' ? theme.palette.grey['300'] : theme.palette.grey['800']} 100%);

	.multi-card-text-wrapper {
		max-width: 600px;
		margin: auto;

		${theme.breakpoints.down('md')} {
			text-align: center;
		}
	}

	.multi-card-image {
		width: 100%;
		height: auto;
		//max-width: 80%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		max-width: 300px;

		// ${theme.breakpoints.up('sm')} {
		// 	max-width: 300px;
		// }
		${theme.breakpoints.up('md')} {
			max-width: none;
		}
	}
`;
