import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.card {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 16px;
		box-shadow: none;
		transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

		${theme.breakpoints.up('md')} {
			background-color: ${theme.palette.background.paper};
			transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
			&:hover {
				box-shadow: -40px 40px 80px ${theme.palette.mode === 'light' ? theme.palette.grey['400'] : theme.palette.grey['800']};
				transform: scale(1.01) rotate(0deg) translate(0, -30px);
				background-color: ${theme.palette.action.hover};
			}
			.card-content {
				min-height: 400px;
				//background-color: transparent;
				//border-radius: 16px;
				//&:hover {
				//	background-color: #fff;
				//	box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
				//}
			}
		}
		${theme.breakpoints.down('md')} {
			box-shadow: -40px 40px 80px ${theme.palette.mode === 'light' ? theme.palette.grey['500'] : theme.palette.grey['800']};
		}
	}
`;
