import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import { amber, grey } from '@mui/material/colors';
import { transparentize } from 'polished';
import { cardColours } from '@helpers/common';

const logoWidth = 35 * 5;
const cardWidth = 86 * 5;
const cardHeight = 54 * 5;
const cardGutter = 7.6 * 5;
const cardLines = 60 * 5;
const cardLineHeight = 3 * 5;

export default (theme: Theme) => css`
	max-width: 320px;
	margin: auto;

	${theme.breakpoints.up('md')} {
		max-width: 372px;
	}

	.card {
		border-radius: 20px;
		position: relative;
		background-color: ${transparentize(0.8, grey['900'])};
		transition: ${theme.transitions.create(['background-color', 'box-shadow'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		})};
		width: 100%;
		min-width: 260px;
		// card aspect ratio (100 / 1.586)
		padding-top: 63.051702396%;

		${theme.breakpoints.up('md')} {
			min-width: 372px;
		}

		&.card-black,
		&.card-silver,
		&.card-gold {
			box-shadow: ${theme.shadows[0]};
		}

		&.card-black {
			background-color: ${cardColours.black};
		}

		&.card-silver {
			background-color: ${cardColours.silver};
		}

		&.card-gold {
			background-color: ${cardColours.gold};
		}

		.card-logo {
			position: absolute;
			top: ${cardGutter * 0.8}px;
			left: ${cardGutter * 0.8}px;
			width: 85%;
			transition: ${theme.transitions.create('fill', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			})};

			// ${theme.breakpoints.down('sm')} {
			// 	top: ${cardGutter * 0.8}px;
			// 	left: ${cardGutter * 0.8}px;
			// }
			//
			// ${theme.breakpoints.between(0, 450)} {
			// 	top: ${cardGutter * 0.7}px;
			// 	left: ${cardGutter * 0.7}px;
			// }

			&,
			* {
				fill: ${theme.palette.common.white};
			}
		}

		&.card-silver,
		&.card-gold {
			.card-logo {
				&,
				* {
					fill: ${theme.palette.common.black};
				}
			}

			.card-lines {
				.card-line {
					.card-first-line,
					.card-second-line {
						background-color: ${transparentize(0.85, theme.palette.common.black)};

						&.card-first-line-filled,
						&.card-second-line-filled {
							color: ${theme.palette.common.black};
						}
					}
				}
			}

			.card-nfc {
				.card-nfc-line {
					background-color: ${theme.palette.common.black};
				}
			}
		}

		.card-nfc {
			position: absolute;
			right: ${cardGutter}px;
			top: 50%;
			transform: translateY(-50%);

			.card-nfc-line {
				width: 3px;
				background-color: ${theme.palette.common.white};
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				transition: ${theme.transitions.create('background-color', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				})};

				&:nth-of-type(1) {
					height: 9px;
					right: 30px;
				}

				&:nth-of-type(2) {
					height: 22px;
					right: 15px;
				}

				&:nth-of-type(3) {
					height: 35px;
					right: 0;
				}
			}
		}

		.card-lines {
			position: absolute;
			bottom: ${cardGutter * 0.8}px;
			left: ${cardGutter * 0.8}px;

			// ${theme.breakpoints.down('sm')} {
			// 	bottom: ${cardGutter * 0.8}px;
			// 	left: ${cardGutter * 0.8}px;
			// }
			//
			// ${theme.breakpoints.between(0, 450)} {
			// 	bottom: ${cardGutter * 0.7}px;
			// 	left: ${cardGutter * 0.7}px;
			// }

			.card-line {
				position: relative;
				width: ${cardLines}px;
				height: ${cardLineHeight}px;
				transition: ${theme.transitions.create('top', {
					duration: theme.transitions.duration.shortest,
				})};

				&.card-line-single {
					top: ${cardLineHeight + cardLineHeight / 2}px;
				}

				.card-first-line,
				.card-second-line {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: ${transparentize(0.85, theme.palette.common.white)};
					transition: ${theme.transitions.create('background-color', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.enteringScreen,
					})};

					&.card-first-line-filled,
					&.card-second-line-filled {
						height: auto;
						background-color: transparent;
						color: #bbbddf;
					}
				}

				p {
					font-size: 1rem;
					text-transform: uppercase;
					letter-spacing: 0.14em;
					font-weight: 700;
					line-height: 1.25;
					margin-top: -4px;

					${theme.breakpoints.between(0, 450)} {
						font-size: 0.8rem;
					}
				}
			}
		}
	}
`;
