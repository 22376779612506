import React, { ReactNode, ReactSVG } from 'react';
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	Paper,
	Stack,
	SvgIcon,
	Typography,
} from '@mui/material';
import styles from './styles';
import { useTheme } from '@mui/material';

const HomeSellCard: React.FC<{ svg?: ReactNode; title?: string; description?: string }> = ({
	svg,
	title,
	description,
}) => {
	const theme = useTheme();
	return (
		<div css={styles(theme)}>
			<Card className="card">
				<CardActionArea>
					<CardContent>
						<Stack
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={4}
							className="card-content"
							sx={{ p: 2, m: 'auto', height: '100%', width: '90%' }}
						>
							<Box sx={{ display: 'flex', justifyContent: 'center' }}>{svg}</Box>
							<Typography variant="h5" component="h3">
								{title}
							</Typography>
							<Typography variant="body2" align="center">
								{description}
							</Typography>
						</Stack>
					</CardContent>
				</CardActionArea>
			</Card>
		</div>
	);
};

export default HomeSellCard;
